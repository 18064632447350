@import url(https://fonts.googleapis.com/css?family=Bangers|Permanent+Marker);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
  max-width: 1500px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.no-padding {
  padding: 0px !important;
}


.home-bg-img {
  width: 100%;
}

.home-container {
  background-image: url("/asset/img/general/vo_bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 500px;
  border-bottom: 1px solid #dddddd;
}

.home-sc-container {
  text-align: center;
  padding-top: 80px;
}

.home-sc-title {
  font-size: 80px;
}

.home-sc-msg {
  font-size: 40px;
}

.free-word {
  font-family: "Bangers", cursive;
  /* font-family: "Permanent Marker", cursive; */
  /* font-family: "Lobster Two", cursive; */
  font-size: 50px;
  color: hotpink;
}

.product-container {
  width: 100%;
  /* height: 400px; */
  background: #f4f3f397;
  text-align: center;
  border-bottom: 1px solid #dddddd;
}

.product-title {
  font-size: 30px;
  font-weight: 400;
  padding-top: 50px;
}

.product-name {
  font-size: 40px;
  font-weight: 700;
}

.custom-hr {
  border-top: 2px solid #555;
  width: 300px;
}

.product-icon-row {
  margin-top: 30px;
  padding-bottom: 50px;
}

.product-icon-img {
  height: 80px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.product-icon-title {
  font-size: 25px;
}

.product-icon-sub-title {
  font-size: 13px;
}

/* .professional-container {
  background-image: url("/asset/img/general/perofbg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  height: 320px;
} */

.professional-title {
  color: white;
  font-size: 40px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.product-pkg-container {
  width: 100%;
  /* height: 400px; */
  background: #f4f3f397;
  text-align: center;
  border-bottom: 1px solid #dddddd;
  padding: 30px 0px;
}

.package_container {
  width: 100%;
  background: white;
  border: 1px #999999 solid;
  border-radius: 20px;
}

.package_container_header {
  font-weight: 500;
  font-size: 25px;
  padding: 20px 0px;
}

.package_container_body {
  padding: 20px 10px;
  border-top: 1px #999999 solid;
  text-align: left;
}

.table_package {
  width: 100%;
}

.table_package td {
  width: 50%;
  border: 1px solid #999999;
}

.package_item_title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.package_item_sub_title {
  font-size: 12px;
}

.whatsapp_icon {
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 100px;
}

.contact_container {
  text-align: left;
  width: 100%;
  background: white;
  border: 1px #999999 solid;
  border-radius: 20px;
  padding: 20px 20px;
}

.contact_title {
  font-size: 20px;
  font-weight: 600;
}

.ask_whatsapp {
  margin: 40px 0px;
  width: 100%;
  max-width: 350px;
}

.footer-bg {
  background: #222222;
}

.footer-container {
  color: white;
  text-align: center;
  padding: 50px 0px;
}

