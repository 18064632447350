@import url("https://fonts.googleapis.com/css?family=Bangers|Permanent+Marker");

.home-bg-img {
  width: 100%;
}

.home-container {
  background-image: url("/asset/img/general/vo_bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 500px;
  border-bottom: 1px solid #dddddd;
}

.home-sc-container {
  text-align: center;
  padding-top: 80px;
}

.home-sc-title {
  font-size: 80px;
}

.home-sc-msg {
  font-size: 40px;
}

.free-word {
  font-family: "Bangers", cursive;
  /* font-family: "Permanent Marker", cursive; */
  /* font-family: "Lobster Two", cursive; */
  font-size: 50px;
  color: hotpink;
}
