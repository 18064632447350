.product-pkg-container {
  width: 100%;
  /* height: 400px; */
  background: #f4f3f397;
  text-align: center;
  border-bottom: 1px solid #dddddd;
  padding: 30px 0px;
}

.package_container {
  width: 100%;
  background: white;
  border: 1px #999999 solid;
  border-radius: 20px;
}

.package_container_header {
  font-weight: 500;
  font-size: 25px;
  padding: 20px 0px;
}

.package_container_body {
  padding: 20px 10px;
  border-top: 1px #999999 solid;
  text-align: left;
}

.table_package {
  width: 100%;
}

.table_package td {
  width: 50%;
  border: 1px solid #999999;
}

.package_item_title {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.package_item_sub_title {
  font-size: 12px;
}
