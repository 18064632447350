.product-container {
  width: 100%;
  /* height: 400px; */
  background: #f4f3f397;
  text-align: center;
  border-bottom: 1px solid #dddddd;
}

.product-title {
  font-size: 30px;
  font-weight: 400;
  padding-top: 50px;
}

.product-name {
  font-size: 40px;
  font-weight: 700;
}

.custom-hr {
  border-top: 2px solid #555;
  width: 300px;
}

.product-icon-row {
  margin-top: 30px;
  padding-bottom: 50px;
}

.product-icon-img {
  height: 80px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.product-icon-title {
  font-size: 25px;
}

.product-icon-sub-title {
  font-size: 13px;
}
