/* .professional-container {
  background-image: url("/asset/img/general/perofbg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  height: 320px;
} */

.professional-title {
  color: white;
  font-size: 40px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
