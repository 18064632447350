.contact_container {
  text-align: left;
  width: 100%;
  background: white;
  border: 1px #999999 solid;
  border-radius: 20px;
  padding: 20px 20px;
}

.contact_title {
  font-size: 20px;
  font-weight: 600;
}

.ask_whatsapp {
  margin: 40px 0px;
  width: 100%;
  max-width: 350px;
}
